<template>
  <div>
    <b-modal
      id="add-material-buy"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('AddMaterial')"
      hide-footer
      size="md"
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="`${$t('Name')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.form.name.$model"
            :state="validateState('name')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          :label="`${$t('Description')}:`"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="$v.form.description.$model"
            :state="validateState('description')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          :label="`${$t('MeasurementType')}:`"
        >
          <b-form-radio-group
            v-model="form.measurementType"
            :options="measurementTypes"
            buttons
            button-variant="primary"
            class="radio-group-custom"
          />
        </b-form-group>
      </b-form>
      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="primary"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('Submit') }}
        </button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"

          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>

      </div>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: '',
        description: '',
        measurementType: 'Meter',
      },
      measurementTypes: [
        { text: this.$t('Meter'), value: 'Meter' },
        { text: this.$t('Part'), value: 'Part' },
        { text: this.$t('Package'), value: 'Package' },
        { text: this.$t('BeadedStrand'), value: 'BeadedStrand' },
        { text: this.$t('Set'), value: 'Set' },
        { text: this.$t('Pair'), value: 'Pair' },
      ],
    }
  },
  validations: {
    form: {
      name: {
        required,
      },
      description: {
        required,
      },
    },
  },
  mounted() {
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$emit('addNewMaterial', this.form)
      this.$refs.modal.hide()
      this.onReset()
    },
    onCancel() {
      this.$refs.modal.hide()
      this.onReset()
    },
    onReset() {
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.form.name = ''
      this.form.description = ''
      this.form.measurementType = 'Meter'
    },
  },
}
</script>

<style lang="scss" scoped>
.radio-group-custom {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    border-radius: 12px;
}
form {
    display: flex;
    flex-direction: column;
}
.flex-form {
    display: flex;
    flex-direction: column;
}
input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -3px;
    left: -1px;
    position: relative;
    background-color: $base-color ;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}
</style>
