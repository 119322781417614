<template>
  <div>
    <b-modal
      id="buy-directly"
      ref="modal"
      header-class="headerModal"
      centered
      size="xl"
      :title="$t('BuyDirect')"
      hide-footer
      @close="onCancel"
    >
      <b-tabs>
        <b-tab>
          <template #title>
            <strong
              style="font-weight: 500; color: black"
            >{{ $t('BuyDirect') }}</strong>
          </template>
          <div class="search-toggle">
            <p>{{ $t('SearchBy') }}</p>
            <div>
              <p
                :class="{ active2: toggleSearch === 'MaterialName' }"
                @click="toggleSearch = 'MaterialName'"
              >
                {{ $t('MaterialName') }}
              </p>
              <p
                :class="{ active2: toggleSearch === 'Code' }"
                @click="toggleSearch = 'Code'"
              >
                {{ $t('Code') }}
              </p>
            </div>
          </div>
          <div
            class="searchfilter"
            style="display: flex;justify-content: space-between;"
          >
            <div
              v-if="toggleSearch === 'MaterialName'"
              style="display: flex; align-items: center"
            >
              <div>
                <div class="black">
                  <b-icon-search
                    v-if="searchMaterial == ''"
                    class="search1"
                    style="position: absolute; left: 5px; top: 12px"
                  />
                  <b-icon-x-circle
                    v-else-if="searchMaterial != ''"
                    class="search1"
                    style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                    @click="searchMaterial = ''"
                  />
                  <input
                    v-model="searchMaterial"
                    type="text"
                    autocomplete="off"
                    name="search-box"
                    :placeholder="$t('MaterialName')"
                    :style="getVariantsDropdownByName.length > 0? 'border-radius: 8px 8px 0px 0px' : ''"
                    @keyup="inputChanged"
                    @keydown.down="onArrowDown"
                    @keydown.up="onArrowUp"
                  >
                </div>
                <div
                  v-if="getVariantsDropdownByName.length > 0"
                  ref="scrollContainer"
                  class="dropdrop"
                  :style="getVariantsDropdownByName.length > 7 ? 'height: 210px' : 'height: auto'"
                >
                  <div
                    v-for="(result, index) in getVariantsDropdownByName"
                    :key="index"
                    ref="options"
                    style="cursor: pointer"
                    class="search-item"
                    :class="{ 'is-active': index === arrowCounter }"
                    @click="searchByOn(result);"
                  >
                    <p style="margin: 0">
                      {{ result.displayVariantName }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="toggleSearch === 'Code'"
              style="display: flex; align-items: center"
            >
              <div>
                <div class="black">
                  <b-icon-search
                    v-if="searchCode == ''"
                    class="search1"
                    style="position: absolute; left: 5px; top: 12px"
                  />
                  <b-icon-x-circle
                    v-else-if="searchCode != ''"
                    class="search1"
                    style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                    @click="searchCode = ''"
                  />
                  <input
                    v-model="searchCode"
                    type="text"
                    autocomplete="off"
                    name="search-box"
                    :placeholder="$t('Code')"
                    :style="getVariantsDropdownByCode.length > 0? 'border-radius: 8px 8px 0px 0px' : ''"
                    @keyup="inputChanged1"
                    @keydown.down="onArrowDown1"
                    @keydown.up="onArrowUp1"
                  >
                </div>
                <div
                  v-if="getVariantsDropdownByCode.length > 0"
                  ref="scrollContainer"
                  class="dropdrop"
                  :style="getVariantsDropdownByCode.length > 7 ? 'height: 210px' : 'height: auto'"
                >
                  <div
                    v-for="(result, index) in getVariantsDropdownByCode"
                    :key="index"
                    ref="options"
                    style="cursor: pointer"
                    class="search-item"
                    :class="{ 'is-active': index === arrowCounter1 }"
                    @click="searchByCode(result);"
                  >
                    <p style="margin: 0">
                      {{ result.displayVariantName }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div style="display:flex;flex-direction: row;;align-items: end;gap:5px;">
              <p style="color:red;margin-top: 5px; margin-bottom: 0px;">
                {{ warningText }}
              </p>
              <b-form-group :label="$t('Vat')">
                <b-form-radio-group
                  v-model="selectedVAT"
                  :options="vatOptions"
                  name="vat-options"
                  buttons
                  button-variant="primary"
                />
              </b-form-group>
            </div>
          </div>

          <div
            class="tt"
            style="padding-top: 16px"
          >
            <table
              class="team_table"
            >
              <thead>
                <tr>
                  <th>{{ $t('Variant') }}</th>
                  <th>{{ $t('Description') }}</th>
                  <th>{{ $t('MeasurementType') }}</th>
                  <th>{{ $t('Amount') }}</th>
                  <th>{{ $t('PriceNoVat') }}</th>
                  <th>{{ $t('Rabat') }}</th>
                  <th>{{ $t('Vat') }}</th>
                  <th>{{ $t('PriceWithVat') }}</th>
                  <th>{{ $t('AmountWithVat') }}</th>
                  <th>{{ $t('Remove') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(material, index) in selectedMaterials"
                  :key="index"
                >
                  <td>{{ material.materialVariantName }}</td>
                  <td>{{ material.description }}</td>
                  <td>{{ material.measurementType }}</td>
                  <td>
                    <b-form-input
                      v-model.number="material.amount"
                      type="number"
                      :placeholder="$t('Amount')"
                      @input="calculateFromAmount(material)"
                    />
                    <p
                      v-if="material.validationamount == true && buttonSubmited == true"
                      style="color:lightcoral"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </td>
                  <td>
                    <b-form-input
                      v-model.number="material.priceWithoutVAT"
                      type="number"
                      :placeholder="$t('Value')"
                      :disabled="material.amount == 0 || material.amount == '' || material.amount == null"
                      @input="calculateFromPriceWithoutVAT(material)"
                    />
                    <p
                      v-if="material.validationPriceWithoutVAT == true && buttonSubmited == true"
                      style="color:lightcoral"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </td>
                  <td>
                    {{ material.rabaat }}
                  </td>
                  <td>
                    <b-form-radio-group
                      v-model="material.selectedVAT"
                      :options="vatOptions"
                      style="display:flex;gap: 5px;align-items: center;"
                      @input="calculateFromSelectedVAT(material)"
                    />
                  </td>
                  <td>
                    <b-form-input
                      v-model.number="material.priceWithVAT"
                      type="number"
                      :placeholder="$t('Value')"
                      :disabled="material.amount == 0 || material.amount == '' || material.amount == null"
                      @input="calculateFromPriceWithVAT(material)"
                    />
                    <p
                      v-if="material.validationPriceWithVAT == true && buttonSubmited == true"
                      style="color:lightcoral"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </td>
                  <td>
                    <b-form-input
                      v-model.number="material.valueWithVAT"
                      type="number"
                      :placeholder="$t('Value')"
                      :disabled="material.amount == 0 || material.amount == '' || material.amount == null"
                      @input="calculateFromValueWithVAT(material)"
                    />
                    <p
                      v-if="material.validationValueWithVAT == true && buttonSubmited == true"
                      style="color:lightcoral"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </td>
                  <td style="text-align: center">
                    <b-icon-dash-circle
                      style="cursor:pointer;font-size: 20px"
                      @click="removeMaterial(index)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p
            v-if="selectedMaterials.length == 0 && buttonSubmited"
            style="color:red;padding-top: 10px"
          >
            {{ $t('NoMaterialVariantWasFound') }}
          </p>
          <div style="display:flex;gap:10px;padding-top: 10px">
            <div
              style="width:100%"
            >
              <b-form-group
                :label="$t('Supplier')"
                style="width:100%"
              >
                <vue-select
                  v-model="mainSupplier"
                  :options="getSupplierDropDown"
                  label="supplierName"
                  :reduce="(e) => e.supplierId"
                  :placeholder="$t('Select')"
                />
              </b-form-group>
              <p
                v-if="mainSupplier == null && buttonSubmited"
                style="color:red;padding-top: 10px"
              >
                {{ $t('Main') }}
              </p>
            </div>
            <div style="width:100%">
              <b-form-group
                :label="$t('Account')"
                style="width:100%"
              >
                <vue-select
                  v-model="accountId"
                  :options="getAccountDetails"
                  label="name"
                  :reduce="(e) => e.accountId"
                  :placeholder="$t('Select')"
                />
              </b-form-group>
              <p
                v-if="accountId == null && buttonSubmited"
                style="color:red;padding-top: 10px"
              >
                {{ $t('SelectAccount') }}
              </p>
            </div>

          </div>
          <div style="display:flex;gap:10px;padding-top: 10px">
            <b-form-group
              :label="$t('TotalPaid')"
              style="width:100%"
            >
              <b-form-input
                v-model.number="totalPaidPriceAmount"
                type="number"
                :placeholder="$t('TotalPaid')"
              />
            </b-form-group>
            <b-form-group
              :label="$t('InvoiceNumber')"
              style="width:100%"
            >
              <b-form-input
                v-model="invoiceNumber"
                :placeholder="$t('InvoiceNumber')"
              />
            </b-form-group>
          </div>
          <div style="display:flex;align-items: center">
            <p style="padding-top:5px;font-weight:700;width:100%;margin: 0px">
              {{ $t('TotalValue') }} : {{ calculateMaterials }} €
            </p>
            <b-form-group
              :label="$t('DateOfInvoice')"
              style="width:100%;padding-top: 5px;"
            >
              <date-picker
                v-model="invoiceDate"
                :placeholder="$t('SelectD')"
                format="YYYY-MM-DD"
                value-type="format"
                style="width:100%"
              />
            </b-form-group>
          </div>
          <div style="padding-top: 10px">
            <AddFile
              ref="addFile1"
              :folder="'OrderPhotos'"
              :label-text="$t('UploadInvoice')"
              :file-data-type="'Contract'"
              :file-name="fileUrl"
            />
          </div>
          <div style="padding-top: 10px">
            <b-form-group :label="$t('Notes')">
              <b-form-textarea
                v-model="notes"
                :placeholder="$t('Write...')"
              />
            </b-form-group>
          </div>
          <div class="buttonsEverywhere">
            <b-button
              type="button"
              variant="none"
              class="buttonSubmit"
              @click="onSubmit"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="danger"
              class="buttonCancel"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <strong
              style="font-weight: 500; color: black"
            >{{ $t('AddMaterial') }}</strong>
          </template>
          <div>
            <div style="display:flex;justify-content: space-between;padding-top: 10px">
              <button
                v-b-modal.add-material-buy
                class="button"
                @click="resetText"
              >
                {{ $t('AddMaterial') }}
              </button>
            </div>
            <div
              class="tt"
              style="padding-top: 10px"
            >
              <p><strong>{{ $t('AddedMaterials') }}</strong></p>
              <p style="color:red">
                {{ textWarning }}
              </p>
              <table
                class="team_table"
              >
                <thead>
                  <tr>
                    <th>{{ $t('MaterialName') }}</th>
                    <th>{{ $t('Unit') }}</th>
                    <th>{{ $t('Description') }}</th>
                    <th>{{ $t('AddNewVariant') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(material, index) in addedMaterials"
                    :key="index"
                  >
                    <td>{{ material.name }}</td>
                    <td>{{ material.measurementType }}</td>
                    <td>{{ material.description }}</td>
                    <td>
                      <button
                        v-b-modal.modal-material
                        class="button"
                        @click="fillItem(material)"
                      >
                        {{ $t('AddNewVariant') }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="tt"
              style="padding-top: 10px"
            >
              <p><strong>{{ $t('AddedMaterialVariants') }}</strong></p>
              <table
                class="team_table"
              >
                <thead>
                  <tr>
                    <th>{{ $t('MaterialName') }}</th>
                    <th>{{ $t('Variant') }}</th>
                    <th>{{ $t('MeasurementType') }}</th>
                    <th>{{ $t('Price') }}</th>
                    <th>{{ $t('SellPrice') }}</th>
                    <th>{{ $t('TotalStockAmount') }}</th>
                    <th>{{ $t('Description') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(material, index) in addedMaterialVariants"
                    :key="index"
                  >
                    <td>{{ material.materialName }}</td>
                    <td>{{ material.name }}</td>
                    <td>{{ material.measurementType }}</td>
                    <td>{{ material.pricePerMeter }}€</td>
                    <td>{{ material.sellPrice }}€</td>
                    <td>{{ material.total }}</td>
                    <td>{{ material.description }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-tab>
        <AddMaterial
          @addNewMaterial="addNewMaterials"
        />
        <AddRowModal
          :item="item"
          @materialvariant="addMaterialV"
        />
      </b-tabs>
    </b-modal>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import AddFile from '@/modals/AddFile.vue'
import DatePicker from 'vue2-datepicker';
import AddRowModal from '@/modals/AddRowModal.vue'
import AddMaterial from '@/components/PurchaseMaterial/AddMaterial.vue'
import 'vue2-datepicker/index.css';

export default {
  components: {
    AddFile,
    DatePicker,
    AddMaterial,
    AddRowModal,
  },
  props: [],
  data() {
    return {
      textWarning: '',
      item: null,
      addedMaterials: [],
      addedMaterialVariants: [],
      addEdit: false,
      buttonSubmited: false,
      notes: null,
      mainSupplier: null,
      accountId: null,
      totalPaidPriceAmount: 0,
      invoiceNumber: null,
      invoiceDate: null,
      fileUrl: [],
      toggleSearch: 'MaterialName',
      noSearch: false,
      searchMaterial: '',
      arrowCounter: 0,
      arrowCounter1: 0,
      enterPressed: false,
      skipWatch: false,
      selectedMaterials: [],
      warningText: '',
      selectedVAT: 18,
      pageSize: 15,
      searchCode: '',
      vatOptions: [
        { text: '18%', value: 18 },
        { text: '8%', value: 8 },
        { text: '0%', value: 0 },
      ],
    }
  },
  computed: {
    ...mapGetters(['getVariantsDropdownByName',
      'getMaterials',
      'getIsLoading',
      'getCurrentPageForMaterials',
      'getTotalItemsForMaterials',
      'getMatVarCurrentPage',
      'getTotalPriceByMaterialId',
      'getVariantsDropdownByCode',
      'getSupplierDropDown',
      'getAccountDetails',
    ]),
    calculateMaterials() {
      if (this.selectedMaterials && this.selectedMaterials.length > 0) {
        return this.selectedMaterials.reduce((total, material) => {
          return total + material.valueWithVAT;
        }, 0);
      }
      return 0;
    },
  },
  watch: {
    selectedMaterials: {
      deep: true,
      handler(newMaterials) {
        newMaterials.forEach((material, index) => {
          this.updatePrices(index);
        });
      },
    },
    selectedVAT(newValue) {
      this.selectedMaterials.forEach((material) => {
        material.selectedVAT = newValue;
      });
    },
    searchMaterial(value) {
      if (this.skipWatch) {
        this.skipWatch = false;
        return;
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.removeLists();
        } else if (!this.enterPressed) {
          this.variantsByDropdownName({
            materialVariantName: value,
            pageNumber: 1,
            pageSize: 50,
          });
        }
      }, 500);
      this.warningText = ''
    },
    searchCode(value) {
      if (this.skipWatch) {
        this.skipWatch = false;
        return;
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.removeLists();
        } else if (!this.enterPressed) {
          this.variantsByDropdownCode({
            code: value,
            pageNumber: 1,
            pageSize: 50,
          });
        }
      }, 500);
      this.warningText = ''
    },
  },
  methods: {
    ...mapActions(['totalPriceByMaterialId',
      'resetMaterialVariant',
      'variantsByDropdownName',
      'addMaterial',
      'loadMaterials',
      'editMaterialReq',
      'loadMaterialVariant',
      'addMaterialVariant',
      'changeLoadingtoTrue',
      'resetVariantsName',
      'variantsByDropdownCode',
      'resetVariantsCode',
      'receiveVariantsAndPurchase',
      'loadSuppliers',
      'loadColors',
      'locationPagination']),
    resetText() {
      this.textWarning = ''
    },
    fillItem(value) {
      this.item = value
      this.loadSuppliers({
        pageNumber: 1,
        pageSize: this.pageSize,
        supplierName: '',
        contactName: '',
        isActive: true,
      });
      this.loadColors({
        colorName: '',
        pageSize: this.pageSize,
        pageNumber: 1,
      });
      this.locationPagination({
        locationType: null,
        locationId: null,
        pageNumber: 1,
        pageSize: this.pageSize,
      })
    },
    addMaterialV(obj) {
      const objekti = {
        materialName: this.item.name,
        pricePerMeter: obj.pricePerMeter,
        description: obj.description,
        measurementType: obj.measurementType,
        name: obj.name,
        sellPrice: obj.sellPrice,
        total: obj.total,
      }
      this.addMaterialVariant({
        object: obj,
        successCallback: () => {
          this.addedMaterialVariants.push(objekti);
        },
      })
    },
    addNewMaterials(value) {
      const measurementType = value.measurementType
      this.addMaterial({
        object: value,
        successCallback: (val) => {
          this.addedMaterials.push({
            ...val,
            measurementType,
          });
          this.textWarning = ''
        },
        errorCallback: (error) => {
          if (error.status === 400 || error.status === 500) {
            this.textWarning = 'This material already exists';
          }
        },
      });
    },
    validateMaterials() {
      this.selectedMaterials.forEach((material) => {
        if (material.valueWithVAT <= 0 || material.valueWithVAT === null || material.valueWithVAT === '') {
          material.validationValueWithVAT = true;
        } else {
          material.validationValueWithVAT = false;
        }

        if (material.amount <= 0 || material.amount === null || material.amount === '') {
          material.validationamount = true;
        } else {
          material.validationamount = false;
        }

        if (material.priceWithoutVAT <= 0 || material.priceWithoutVAT === null || material.priceWithoutVAT === '') {
          material.validationPriceWithoutVAT = true;
        } else {
          material.validationPriceWithoutVAT = false;
        }

        if (material.priceWithVAT <= 0 || material.priceWithVAT === null || material.priceWithVAT === '') {
          material.validationPriceWithVAT = true;
        } else {
          material.validationPriceWithVAT = false;
        }
      });
    },
    calculateFromAmount(material) {
      if (material.amount > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.amount).toFixed(2));
      }
      this.validateMaterials()
    },

    calculateFromPriceWithVAT(material) {
      if (material.amount > 0) {
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.amount).toFixed(2));
      } else {
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    calculateFromSelectedVAT(material) {
      if (material.amount > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.amount).toFixed(2));
      } else if (material.priceWithoutVAT > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
      }

      if (material.priceWithVAT > 0) {
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    calculateFromPriceWithoutVAT(material) {
      if (material.amount > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.amount).toFixed(2));
      } else {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    calculateFromValueWithVAT(material) {
      if (material.amount > 0) {
        material.priceWithVAT = parseFloat((material.valueWithVAT / material.amount).toFixed(2));
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    removeMaterial(index) {
      this.selectedMaterials.splice(index, 1)
    },
    async searchByOn(value) {
      const duplicate = this.selectedMaterials.find((material) => { return material.materialVariantId === value.materialVariantId });

      if (duplicate) {
        this.warningText = 'There is already a material.';
        this.resetVariantsName();
        this.skipWatch = true;
        this.searchMaterial = '';
        return;
      }
      this.selectedMaterials.push({
        materialVariantName: value.materialVariantName,
        description: value.description,
        materialVariantId: value.materialVariantId,
        measurementType: value.measurementType,
        valueWithVAT: 0,
        amount: 0,
        priceWithVAT: 0,
        rabaat: 0,
        priceWithoutVAT: 0,
        selectedVAT: this.selectedVAT,
        validationPriceWithoutVAT: true,
        validationamount: true,
        validationPriceWithVAT: true,
        validationValueWithVAT: true,
      });
      this.skipWatch = true;
      this.searchMaterial = '';
      this.resetVariantsName();
    },
    async searchByCode(value) {
      const duplicate = this.selectedMaterials.find((material) => { return material.materialVariantId === value.materialVariantId });

      if (duplicate) {
        this.warningText = 'There is already a material.';
        this.resetVariantsCode();
        this.skipWatch = true;
        this.searchCode = '';
        return;
      }
      this.selectedMaterials.push({
        materialVariantName: value.materialVariantName,
        description: value.description,
        materialVariantId: value.materialVariantId,
        measurementType: value.measurementType,
        valueWithVAT: 0,
        amount: 0,
        priceWithVAT: 0,
        rabaat: 0,
        priceWithoutVAT: 0,
        selectedVAT: this.selectedVAT,
        validationPriceWithoutVAT: true,
        validationamount: true,
        validationPriceWithVAT: true,
        validationValueWithVAT: true,
      });
      this.skipWatch = true;
      this.searchCode = '';
      this.resetVariantsCode();
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getVariantsDropdownByName.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getVariantsDropdownByName[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    onArrowDown1(ev) {
      ev.preventDefault()
      if (this.arrowCounter1 < this.getVariantsDropdownByCode.length - 1) {
        this.arrowCounter1 += 1;
        this.fixScrolling();
      }
    },
    onArrowUp1(ev) {
      ev.preventDefault()
      if (this.arrowCounter1 > 0) {
        this.arrowCounter1 -= 1;
        this.fixScrolling()
      }
    },
    inputChanged1(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByCode(this.getVariantsDropdownByCode[this.arrowCounter1])
        this.removeLists()
        this.arrowCounter1 = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    removeLists() {
      this.resetVariantsName();
      this.resetVariantsCode();
    },
    async onSubmit() {
      this.buttonSubmited = true;

      if (this.buttonSubmited) {
        this.selectedMaterials.forEach((material) => {
          if (
            material.valueWithVAT <= 0
            || material.valueWithVAT === null
            || material.valueWithVAT === ''
          ) {
            material.validationValueWithVAT = true;
          } else {
            material.validationValueWithVAT = false;
          }

          if (
            material.amount <= 0
            || material.amount === null
            || material.amount === ''
          ) {
            material.validationamount = true;
          } else {
            material.validationamount = false;
          }

          if (
            material.priceWithoutVAT <= 0
            || material.priceWithoutVAT === null
            || material.priceWithoutVAT === ''
          ) {
            material.validationPriceWithoutVAT = true;
          } else {
            material.validationPriceWithoutVAT = false;
          }

          if (
            material.priceWithVAT <= 0
            || material.priceWithVAT === null
            || material.priceWithVAT === ''
          ) {
            material.validationPriceWithVAT = true;
          } else {
            material.validationPriceWithVAT = false;
          }
        });
      }

      const hasErrors = this.selectedMaterials.some(
        (material) => {
          return material.validationValueWithVAT || material.validationamount || material.validationPriceWithoutVAT || material.validationPriceWithVAT
        },
      );

      if (this.selectedMaterials.length === 0 || this.mainSupplier == null || this.accountId == null || hasErrors) {
        return
      }
      try {
        await this.$refs.addFile1.uploadFile();
        const purchaseDirectVariantItemRequestDtos = this.selectedMaterials.map((item) => {
          return {
            materialVariantId: item.materialVariantId,
            newDeliveryAmount: item.amount,
            totalPurchasePrice: item.valueWithVAT,
            taxRate: item.selectedVAT,
          }
        })
        const objekti = {
          purchaseDirectVariantItemRequestDtos,
          fileUrl: this.fileUrl,
          mainSupplierId: this.mainSupplier,
          accountId: this.accountId,
          totalPaidPriceAmount: this.totalPaidPriceAmount,
          notes: this.notes,
          invoiceNumber: this.invoiceNumber,
          invoiceDate: this.invoiceDate,
        }
        this.$emit('receiveVariants', objekti)
        this.$refs.modal.hide()
        this.onReset()
      } catch (error) {
        console.error(error)
      }
    },
    onCancel() {
      this.$refs.modal.hide()
      this.onReset()
    },
    onReset() {
      this.buttonSubmited = false
      this.noSearch = false
      this.searchMaterial = ''
      this.searchCode = ''
      this.arrowCounter = 0
      this.enterPressed = false
      this.skipWatch = false
      this.selectedMaterials = []
      this.warningText = ''
      this.resetVariantsName();
      this.selectedVAT = 18
      this.mainSupplier = null
      this.accountId = null
      this.totalPaidPriceAmount = 0
      this.invoiceNumber = null
      this.invoiceDate = null
      this.notes = null
      this.fileUrl = []
      this.addedMaterials = []
      this.addedMaterialVariants = []
      this.item = null
      this.textWarning = ''
    },
  },
}
</script>

<style>
.active2{
  color: #212529;
  font-weight: bold;
}
.btn-group > :not(.btn-check:first-child) + .btn, .btn-group > .btn-group:not(:first-child) {
    margin-left: calc(var(--bs-border-width)* -1);
    display: flex;
    align-items: center;
    gap: 5px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    align-items: center;
    gap: 5px;
}

input[type=radio]:checked:after {
    width: 17px !important;
    height: 17px !important;
    border-radius: 15px;
    top: -3px;
    left: -1px;
    position: relative;
    background-color: #242F6E;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}
.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
.dropdrop::-webkit-scrollbar {
  display: none;
}

.team_table td {
  padding: 9px 9px !important;
}

thead tr th {
    background: #f4f5f6 !important;
    color: #475467 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;
  z-index:3;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

.is-active {
  background-color: #dedede;
}
</style>
