<template>
  <div>
    <div style="display:flex;justify-content: space-between;">
      <button
        v-b-modal.buy-directly
        class="button"
        @click="callSuppliers"
      >
        {{ $t('Buy') }}
      </button>
    </div>
    <div style="margin-top: 20px">
      <div style="display: flex; flex-direction: row; gap: 10px">
        <div style="display: flex; flex-direction: column; width: 15%; justify-content: space-between; margin-top: 5px;">
          <b-form-group
            :label="$t('SelectState')"
            style="margin-bottom: 20px"
          >
            <vue-select
              v-model="selectedOrderCartType"
              class="custom-select2"
              :placeholder="$t('SelectState')"
              :options="cartTypes"
              :clearable="false"
              :reduce="(e) => e.value"
              label="text"
            />
          </b-form-group>
        </div>
        <div style="display: flex; margin-bottom: 5px; width: 65%">
          <b-form-group
            :label="$t('EditY')"
          >
            <div
              class="year"
              style="width: 100% !important"
            >
              <button
                class="arrowButton"
                @click="previousYear"
              >
                <b-icon-arrow-left />
              </button>
              <date-picker
                v-model="selectedYear"
                value-type="format"
                :clearable="false"
                class="form-control"
                format="YYYY"
                type="year"
                :placeholder="$t('EditY')"
              />
              <button
                class="arrowButton"
                style="margin-left: 5px"
                @click="nextYear"
              >
                <b-icon-arrow-right />
              </button>
            </div>
          </b-form-group>
          <b-form-group
            :label="$t('SelectedMonth')"
          >
            <div class="year">
              <date-picker
                v-model="selectedMonth"
                value-type="format"
                class="form-control"
                format="MMMM"
                type="month"
                :placeholder="$t('Month')"
              />
            </div>
          </b-form-group>
        </div>
      </div>
      <div
        class="tt"
        style="padding-top: 16px"
      >
        <table
          class="team_table"
        >
          <thead>
            <tr>
              <th>
                {{ $t('OrderNumber') }}
              </th>
              <th>
                {{ $t('Supplier') }}
              </th>
              <th>
                {{ $t('State') }}
              </th>
              <th>
                {{ $t('InvoiceNumber') }}
              </th>
              <th>
                {{ $t('DateOfInvoice') }}
              </th>
              <th>
                {{ $t('TotalPaid') }}
              </th>
              <th>
                {{ $t('Files') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(orders, index) in filteredOrders"
              :key="index"
              v-b-toggle.order-tracking-buy
              v-b-tooltip.hover
              :title="$t('Tracking')"
              class="hover-row"
              :style="classNameCh === orders.id ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
              @click="sendProps(orders)"
            >
              <td>
                {{ orders.orderNumber }}
              </td>
              <td>
                {{ orders.mainSupplier }}
              </td>
              <td>
                <div style="background-color: rgb(172, 255, 172); padding: 6px; border-radius: 7px; color: rgb(2, 102, 2); cursor: default; width: 60%">
                  ⦿ {{ $t(orders.state) }}
                </div>
              </td>
              <td>
                {{ orders.invoiceNumber }}
              </td>
              <td>
                {{ dateFormat(orders.invoiceDate) }}
              </td>
              <td>
                {{ orders.totalPaidAmount }}
              </td>
              <td
                v-if="orders.photos.length > 0"
                style="display: flex; flex-wrap: wrap; gap: 3px;"
              >
                <template v-for="(photo, photoIndex) in orders.photos">
                  <img
                    v-if="photo.endsWith('.pdf') || photo.endsWith('.docx') || photo.endsWith('.xlsm') || photo.endsWith('.xlsx') || photo.endsWith('.plain')"
                    :key="'doc-' + photoIndex"
                    v-b-tooltip="$t('Document')"
                    style="width:30px;height:30px"
                    src="../../assets/images/documentLogo.svg"
                  >
                  <button
                    v-if="photo.endsWith('.pdf') || photo.endsWith('.docx') || photo.endsWith('.xlsm') || photo.endsWith('.xlsx') || photo.endsWith('.plain')"
                    :key="'btn-' + photoIndex"
                    v-b-tooltip="$t('Download')"
                    style="border: 1px solid #FF274F; width: 35px; height: 35px; background: white; border-radius: 5px; margin-right: 10px;"
                    @click.stop="downloadFile(photo)"
                  >
                    <b-icon-download style="color: #FF274F;" />
                  </button>

                  <img
                    v-if="!photo.endsWith('.pdf') && !photo.endsWith('.docx') && !photo.endsWith('.xlsm') && !photo.endsWith('.xlsx') && !photo.endsWith('.plain')"
                    :key="'img-' + photoIndex"
                    v-b-tooltip="$t('ClickToViewPhoto')"
                    style="width:30px;height:30px; border-radius: 3px;"
                    :src="photo"
                    alt="No image"
                    @click.stop="callImagesMethod(orders)"
                  >
                  <button
                    v-if="!photo.endsWith('.pdf') && !photo.endsWith('.docx') && !photo.endsWith('.xlsm') && !photo.endsWith('.xlsx') && !photo.endsWith('.plain')"
                    :key="'img-btn-' + photoIndex"
                    v-b-tooltip="$t('Download')"
                    style="border: 1px solid #FF274F; width: 35px; height: 35px; background: white; border-radius: 5px; margin-right: 10px;"
                    @click.stop="downloadFile(photo)"
                  >
                    <b-icon-download style="color: #FF274F;" />
                  </button>
                </template>
              </td>
              <td v-else>
                <img
                  v-b-tooltip="$t('NoImageOrDocument')"
                  style="width:30px;height:30px"
                  src="../../assets/images/noImageAvailable.svg"
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <b-pagination
            v-if="getTotalItemsForMaterialOrders > 15"
            v-model="page"
            :total-rows="getTotalItemsForMaterialOrders"
            :per-page="pageSize"
            first-number
            last-number
            align="fill"
            size="md"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
          >
            <template #prev-text>
              <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
            </template>
            <template #next-text>
              <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
            </template>
          </b-pagination>
        </div>
        <div
          v-if="isSidebarOpen"
          class="backdrop"
        />
        <TrackingByOrder
          @onCancelToggle="onCancelToggle"
        />
        <vue-easy-lightbox
          :esc-disabled="false"
          move-disabled
          :visible="image.visible"
          :imgs="image.imgs"
          :index="image.index"
          @hide="handleHide"
        />
      </div>
    </div>
    <BuyDirectModal
      @receiveVariants="receiveAllVariants"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VueEasyLightbox from 'vue-easy-lightbox';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import TrackingByOrder from '@/components/PurchaseMaterial/TrackingByOrder.vue'
import BuyDirectModal from './BuyDirectModal.vue';
import 'vue2-datepicker/index.css';

export default {
  components: {
    BuyDirectModal,
    DatePicker,
    VueEasyLightbox,
    TrackingByOrder,
  },
  props: [],
  data() {
    return {
      orderCartId: null,
      selectedYear: new Date().getFullYear().toString(),
      selectedMonth: null,
      isTextShown: false,
      hoveredIndex: null,
      materialVariantId: null,
      image: {
        zoom: 1,
        imgs: [],
        visible: false,
        index: 0,
      },
      selectedOrderState: 'Received',
      selectedOrderCartType: 'Direct',
      cartTypes: [
        { value: 'Direct', text: this.$t('Direct') },
        { value: 'Cart', text: this.$t('Cart') },
      ],
      classNameCh: '',
      page: 1,
      pageSize: 15,
      order: null,
      isSidebarOpen: false,
    }
  },
  computed: {
    ...mapGetters(['getLanguage', 'getImagesVariantOrder', 'getOrderedMaterials', 'getTotalItemsForMaterialOrders']),
    filteredOrders() {
      return this.getOrderedMaterials
    },
  },
  watch: {
    page(value) {
      this.loadOrderedMaterials({
        orderCartType: this.selectedOrderCartType,
        state: this.selectedOrderState,
        month: this.selectedMonth ? new Date(`${this.selectedMonth} 1`).toLocaleDateString(undefined, { month: '2-digit' }) : null,
        year: this.selectedYear,
        pageNumber: value,
        pageSize: this.pageSize,
      })
      this.clearLoadOrderItems()
    },
    selectedOrderCartType(value) {
      this.loadOrderedMaterials({
        orderCartType: value,
        state: this.selectedOrderState,
        month: this.selectedMonth ? new Date(`${this.selectedMonth} 1`).toLocaleDateString(undefined, { month: '2-digit' }) : null,
        year: this.selectedYear,
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
      this.clearLoadOrderItems()
    },
    selectedYear(newDate) {
      this.loadOrderedMaterials({
        orderCartType: this.selectedOrderCartType,
        state: this.selectedOrderState,
        month: this.selectedMonth ? new Date(`${this.selectedMonth} 1`).toLocaleDateString(undefined, { month: '2-digit' }) : null,
        year: newDate,
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
    },
    selectedMonth(newDate) {
      if (newDate == null) {
        this.loadOrderedMaterials({
          orderCartType: this.selectedOrderCartType,
          state: this.selectedOrderState,
          year: this.selectedYear,
          pageNumber: this.page,
          pageSize: this.pageSize,
        })
      } else {
        newDate = new Date(`${newDate} 1`).toLocaleDateString(undefined, { month: '2-digit' });
        this.loadOrderedMaterials({
          orderCartType: this.selectedOrderCartType,
          state: this.selectedOrderState,
          month: newDate || null,
          year: this.selectedYear,
          pageNumber: this.page,
          pageSize: this.pageSize,
        })
      }
      this.clearLoadOrderItems()
    },
  },
  mounted() {
    this.loadOrderedMaterials({
      orderCartType: this.selectedOrderCartType,
      state: this.selectedOrderState,
      year: this.selectedYear,
      pageNumber: this.page,
      pageSize: this.pageSize,
    })
  },
  methods: {
    ...mapActions(['suppliersDropDown', 'receiveVariantsAndPurchase', 'accountDetails', 'clearLoadOrderItems', 'accountDetails', 'receiveMaterialVariants', 'loadOrderedMaterials', 'loadOrderItems', 'loadTrackingMaterialOrder', 'changeStateOfMaterialOrder', 'getImagesOrderItemId', 'deleteFileFromMaterialOrder', 'loadOrderItemsByCardId']),
    dateFormat(value) {
      if (value) {
        return moment(value).format('YYYY-MM-DD');
      }
      return 'No Date'
    },
    downloadFile(fileUrl) {
    // This method will handle both download and preview of the file
      const fileExtension = fileUrl.split('.').pop();

      // If the file is already a PDF, no need to generate it
      if (fileExtension === 'pdf') {
        this.generateAndPreviewPdf(fileUrl);
      } else {
      // If the file is not a PDF, handle the conversion if needed
      // You can add specific logic for .docx, .xlsx, etc. if required
        console.log('Downloading non-PDF file:', fileUrl);

        // Handle the logic to download or preview non-PDF files here (optional)
        this.downloadNonPdfFile(fileUrl); // Custom method to handle this
      }
    },

    generateAndPreviewPdf(pdfUrl) {
    // Generate a Blob from the URL
      fetch(pdfUrl)
        .then((response) => { return response.blob() })
        .then((pdfBlob) => {
          const blobUrl = URL.createObjectURL(pdfBlob);
          // Save the Blob URL to sessionStorage
          sessionStorage.setItem('pdfBlobUrl', blobUrl);
          sessionStorage.setItem('pathName', this.$route.path);
          // Redirect to the PDF preview page
          this.$router.push({ name: 'Pdf Preview' }); // Ensure you have a route named 'PdfPreview'
        })
        .catch((error) => {
          console.error('Error generating PDF:', error);
        });
    },

    // getOrderedMaterialPagination

    downloadNonPdfFile(fileUrl) {
    // For non-PDF files, you can either download or display them differently
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = true; // Trigger download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    zoomIn(index) {
      this.hoveredIndex = index;
    },
    zoomOut() {
      this.hoveredIndex = null;
    },
    showText() {
      this.isTextShown = true;
    },
    previousYear() {
      this.selectedYear = moment(this.selectedYear, 'YYYY').subtract(1, 'year').format('YYYY');
    },
    nextYear() {
      this.selectedYear = moment(this.selectedYear, 'YYYY').add(1, 'year').format('YYYY');
    },
    sendProps(value) {
      this.isSidebarOpen = true
      this.classNameCh = value.id
      this.loadOrderItems({ orderCartId: value.id })
    },
    onCancelToggle() {
      this.isSidebarOpen = false
    },
    async callImagesMethod(item) {
      this.$store.commit('SET_IMAGES_PRODUCTION_MANAGEMENT', [{
        fileDataId: 'aaf153a4-be48-ee11-abf3-124abb807f69',
        mainDressColorId: 'e2b146ea-a7e9-4584-ee19-08dba4a55447',
        url: '',
        fileName: '1693569414176-createordermodal.png',
      }]);
      try {
        await this.getImagesOrderItemId(item.id).then((res) => {
          console.log(res);
        })
      } catch (e) {
        console.log(e);
      }
      this.image.imgs = []
      for (let i = 0; i < this.getImagesVariantOrder.length; i++) {
        const image = this.getImagesVariantOrder[i]
        this.image.imgs.push({
          src: image.url,
          title: image.fileName,
          fileDataId: image.fileDataId,
          fileName: image.fileName,
        })
      }
      this.show()
    },
    show() {
      this.image.visible = true
    },
    handleHide() {
      this.image.visible = false
      this.image.imgs.length = 0
    },
    async handleDeleteClick(item) {
      try {
        await this.getImagesOrderItemId(item.id).then((res) => {
          console.log(res);
        })
      } catch (e) {
        console.log(e);
      }
      this.image.imgs = []
      for (let i = 0; i < this.getImagesVariantOrder.length; i++) {
        const image = this.getImagesVariantOrder[i]
        this.image.imgs.push({
          src: image.url,
          title: image.fileName,
          fileDataId: image.fileDataId,
          fileName: image.fileName,
        })
      }
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this photo?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        cancelVariant: 'default',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then((value) => {
          if (value) {
            this.deleteFileFromMaterialOrder({
              id: this.image.imgs[0].fileDataId,
              successCallback: () => {
                this.loadOrderedMaterials({
                  orderCartType: this.selectedOrderCartType,
                  state: this.selectedOrderState,
                  month: this.selectedMonth ? new Date(`${this.selectedMonth} 1`).toLocaleDateString(undefined, { month: '2-digit' }) : null,
                  year: this.selectedYear,
                  pageNumber: this.page,
                  pageSize: this.pageSize,
                })
              },
            });
          }
        })
        .catch((err) => {
          console.log('Error in msgBoxConfirm:', err);
        });
    },
    receiveAllVariants(value) {
      this.receiveVariantsAndPurchase({
        object: value,
        successCallback: () => {
          this.loadOrderedMaterials({
            orderCartType: this.selectedOrderCartType,
            month: this.selectedMonth ? moment(this.selectedMonth, 'MMMM').format('MM') : null,
            state: this.selectedOrderState,
            year: this.selectedYear,
            pageNumber: this.page,
            pageSize: this.pageSize,
          })
        },
      })
    },
    callSuppliers() {
      this.suppliersDropDown({
        isActive: true,
      })
      this.accountDetails({
        isActive: true,
      })
    },
  },
}
</script>

<style scoped lang="scss">

.team_table td {
  padding: 11px 9px !important;
}

.buttonOrdered {
  border: none;
}

.buttonOrdered:hover {
  background: white;
  color: #FF274F;
}


.team_table thead {
  border-bottom: 1px solid #dcdfe6;
  color: #606266;
  text-align: left;
}

.team_table tr {
  border-bottom: 1px solid #dcdfe6;
  color: #606266;
  cursor: pointer;
}
.hover-row:hover {
  background-color: rgba(255, 39, 79, 0.1);
}
.mats-listing__wrapper {
  width: 600px !important;

.tt {
  .vgt-table {
    th.sortable button {
      display: none !important;
    }
  }
}

.mats-listing {
  margin-top: 0;
}
}

.button {
  margin-bottom: 15px !important;
  width: 195px;
}

// .team_table .tr{
//   border-radius: 10px 10px 10px 10px !important;
// }
tr:last-child {
  border-bottom: none !important;
}

@media screen and (max-width: 1723px) {
.mats-listing__wrapper {
  width: 400px !important;

  div {
    button {
      width: auto;
      height: 36px;
    }

    input {
      width: 160px;
    }

  }

  .table__search-input {
    padding-left: 0px;
    margin-left: 0px !important;
  }
}

}

@media screen and (max-width: 1400px) {
  .mats-listing__wrapper {
    width: 100% !important;
  }
}


.dropdrop::-webkit-scrollbar {
  display: none;
}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.black {
  position: relative;
}

.black input {
  border: none;
  box-shadow: none;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.bigger-icon {
  font-size: 24px;
  align-self: center;
}
.photo{
  margin-left: 5px;
  height: 50px;
  border-radius: 8px;
}
.year {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.arrowButton {
  width: 36px !important;
  height: 36px;
  display: flex;
  margin-right: 5px;
  border-radius: 6px !important;
  justify-content: center;
  border: #c3d0dd 1px solid;
  background-color: #e9ecef;
  align-items: center;
}
.hovered {
  /* Additional styles for the hovered photo */
  transform: scale(7.2);
  border-radius: 0px;
}
.second-photo{
  margin-left: 10px;
}
.third-photo{
  margin-left: 10px;
}

.is-active {
  background-color: #dedede;
}
</style>
