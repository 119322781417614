<template>
  <div>
    <b-sidebar
      id="order-tracking-buy"
      ref="modal"
      header-class="headerModalToggle"
      right
      shadow
      size="l"
      width="60%"
      :title="$t('OrdersHistory')"
      no-close-on-esc
      @close="onCancelToggle"
    >
      <template #title>
        <strong style="color: #101828;">{{ $t('OrdersHistory') }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3; padding-left: 0px; padding-right: 0px; height: 60px"
          class="fa fa-close"
          @click="onCancelToggle"
        />
      </template>
      <div
        class="tt"
        style="padding: 16px"
      >
        <table
          class="team_table"
        >
          <thead>
            <tr>
              <th>{{ $t('Variant') }}</th>
              <th>{{ $t('Color') }}</th>
              <th>{{ $t('MainSupplier') }}</th>
              <th>{{ $t('Amount') }}</th>
              <th>{{ $t('PriceNoVat') }}</th>
              <th>{{ $t('Vat') }}</th>
              <th>{{ $t('PriceWithVat') }}</th>
              <th>{{ $t('AmountWithVat') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(material, index) in selectedMaterials"
              :key="index"
            >
              <td>{{ material.materialVariantName }}</td>
              <td>{{ material.color }}</td>
              <td>{{ material.mainSupplierName }}</td>
              <td>{{ material.amount }}</td>
              <td>{{ material.priceWithoutVAT }}€</td>
              <td>{{ material.selectedVAT ? material.selectedVAT : 0 }}%</td>
              <td>{{ material.priceWithVAT }}€</td>
              <td>{{ material.valueWithVAT ? material.valueWithVAT : 0 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment'

export default {
  components: {
  },
  data() {
    return {
      vatOptions: [
        { text: '18%', value: 18 },
        { text: '8%', value: 8 },
        { text: '0%', value: 0 },
      ],
    };
  },
  computed: {
    ...mapGetters(['getOrderItems']),
    selectedMaterials() {
      return this.getOrderItems.map((value) => {
        const priceWithoutVAT = value.amount > 0 ? (value.totalPrice / value.amount) / (1 + value.taxRate / 100) : 0;
        const priceWithVAT = value.amount > 0 ? value.totalPrice / value.amount : 0;
        const formatPrice = (price) => {
          return price % 1 === 0 ? price : price.toFixes(2);
        }
        return {
          materialVariantName: value.materialVariantName,
          color: value.color,
          materialVariantId: value.materialVariantId,
          mainSupplierName: value.mainSupplierName,
          valueWithVAT: value.totalPrice,
          amount: value.amount,
          priceWithVAT: formatPrice(priceWithVAT),
          priceWithoutVAT: formatPrice(priceWithoutVAT),
          selectedVAT: value.taxRate,
        }
      })
    },
    totalAmount() {
      let sum = 0;
      this.getOrderItems.forEach((item) => {
        sum += item.amount * item.orderedPrice;
      });
      return `${this.numbersWithcomma(sum)}€`
    },
  },
  methods: {
    ...mapActions(['loadOrderedMaterials']),
    validateMaterials() {
      this.selectedMaterials.forEach((material) => {
        if (material.valueWithVAT <= 0 || material.valueWithVAT === null || material.valueWithVAT === '') {
          material.validationValueWithVAT = true;
        } else {
          material.validationValueWithVAT = false;
        }

        if (material.amount <= 0 || material.amount === null || material.amount === '') {
          material.validationamount = true;
        } else {
          material.validationamount = false;
        }

        if (material.priceWithoutVAT <= 0 || material.priceWithoutVAT === null || material.priceWithoutVAT === '') {
          material.validationPriceWithoutVAT = true;
        } else {
          material.validationPriceWithoutVAT = false;
        }

        if (material.priceWithVAT <= 0 || material.priceWithVAT === null || material.priceWithVAT === '') {
          material.validationPriceWithVAT = true;
        } else {
          material.validationPriceWithVAT = false;
        }
      });
    },
    calculateFromAmount(material) {
      if (material.amount > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.amount).toFixed(2));
      }
      this.validateMaterials()
    },

    calculateFromPriceWithVAT(material) {
      if (material.amount > 0) {
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.amount).toFixed(2));
      } else {
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    calculateFromSelectedVAT(material) {
      if (material.amount > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.amount).toFixed(2));
      } else if (material.priceWithoutVAT > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
      }

      if (material.priceWithVAT > 0) {
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    calculateFromPriceWithoutVAT(material) {
      if (material.amount > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.amount).toFixed(2));
      } else {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    calculateFromValueWithVAT(material) {
      if (material.amount > 0) {
        material.priceWithVAT = parseFloat((material.valueWithVAT / material.amount).toFixed(2));
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    createdDateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY - HH:mm');
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    onCancelToggle() {
      this.$emit('onCancelToggle');
      this.$refs.modal.hide();
    },
  },
}
</script>
